import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Link } from 'site-modules/shared/components/link/link';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import './top-rated-promo-unit.scss';

const TOP_RATED_IMG_PATH = getStaticImageUrl('/images/top-rated/2025/');
const TOP_RATED_LOGO = `${TOP_RATED_IMG_PATH}top-rated-logo.svg`;
const TOP_RATED_CAR = `${TOP_RATED_IMG_PATH}top-rated-car.png`;
const TOP_RATED_CAR_LOGO = `${TOP_RATED_IMG_PATH}top-rated-car-logo.png`;
const DC_TDV = 'dc_tdv=1?https://www.edmunds.com/top-rated/best-of-the-best/';
const TOP_RATED_URL = {
  // desktop
  false: `https://ad.doubleclick.net/ddm/trackclk/N8821.2990.EDMUNDS.COMINC/B33067446.411506461;dc_trk_aid=605163121;dc_trk_cid=105363203;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;${DC_TDV}`,
  // mobile
  true: `https://ad.doubleclick.net/ddm/trackclk/N8821.2990.EDMUNDS.COMINC/B33067446.411669368;dc_trk_aid=605163121;dc_trk_cid=105363203;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;${DC_TDV}`,
};
const ADS_PROPS = {
  // desktop
  false: { adName: 'globalbanner', md: true, lg: true, xl: true },
  // mobile
  true: { adName: 'mglobalbanner', xs: true, sm: true },
};

export function TopRatedPromoUnit({ className, isMobile }) {
  const topRatedLogo = (
    <div>
      <img src={TOP_RATED_LOGO} alt="" className="w-100 mb-0_25" />
    </div>
  );

  return (
    <FeatureFlag name="top-rated-promo">
      <div
        className={classnames('top-rated-banner pos-r rounded mx-1_5 mx-md-0', className)}
        data-tracking-parent="edm-top-rated-banner"
      >
        <Row
          tag={Link}
          to={TOP_RATED_URL[isMobile]}
          rel="nofollow"
          data-tracking-id="view_content"
          className="pos-r no-focus text-decoration-none py-0_5 py-lg-1 px-0_5 px-lg-1 mx-0 align-items-center"
        >
          <Col
            xs={7}
            lg={4}
            className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between px-0"
          >
            <div>
              <img src={TOP_RATED_CAR_LOGO} alt="" className="car-logo" />
            </div>
            <div className="hidden-lg-up">{topRatedLogo}</div>
            <div className="car-img">
              <img src={TOP_RATED_CAR} alt="" className="w-lg-100" />
            </div>
          </Col>
          <Col className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center justify-content-between">
            <div className="ms-xl-1 px-lg-1 mb-0_25">
              <div className="hidden-md-down">{topRatedLogo}</div>
              <div className="rated-text fw-medium text-white text-end text-lg-center">
                <div className="d-lg-inline">
                  Honda Civic Hybrid <span className="hidden-md-down">|</span>{' '}
                </div>
                <div className="d-lg-inline fst-italic">Best of the Best Winner</div>
              </div>
            </div>
            <div>
              <div className="pos-r overflow-hidden btn text-cool-gray-100 fw-bold text-shadow-contrast border-0 px-0_75 px-lg-2_5 py-0_5 py-lg-0_75">
                See Why It Won
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <AdUnit {...ADS_PROPS[isMobile]} oneByOne renderWhenViewable={false} />
    </FeatureFlag>
  );
}

TopRatedPromoUnit.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

TopRatedPromoUnit.defaultProps = {
  className: null,
  isMobile: false,
};
