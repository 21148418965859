import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { times } from 'lodash';
import { connect } from 'react-redux';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { HomeModulesModel } from 'client/data/models/home-modules';
import { DEFAULT_CONTENT, transform } from 'client/data/cms/content';
import { CmsEntities } from 'client/data/models/cms';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { StickyIcon } from 'site-modules/shared/components/sticky-icon/sticky-icon';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

import './home-marquee.scss';

const HERO_HPM_ADS = 4;
const HERO_HPM_ADS_PROPS = {
  // desktop
  false: { adName: 'hpmarquee', md: true, lg: true, xl: true },
  // mobile
  true: { adName: 'mhpmarquee', xs: true, sm: true },
};

const URL_TARGET = { true: '_blank', false: '_self' };
const TRACKING_ID = { true: 'hero_module_link_click_out', false: 'hero_module_link_click' };
const COL_COUNT = 2;

function Card({ item, isMobile, hasRowDisclaimer, isTextOverlay, position }) {
  const aspectRatio = isMobile ? '25:16' : '8:3';
  const suffix = isMobile ? 'Mobile' : '';
  const urlTargetNew = item.metadata('urlTargetNew').boolean();

  return (
    <a
      href={item.metadata(`url${suffix}`).value()}
      target={URL_TARGET[urlTargetNew]}
      data-tracking-id={TRACKING_ID[urlTargetNew]}
      data-tracking-value={item.metadata('verbiageNoMarkup').value()}
      data-tracking-position={position}
      rel="noopener noreferrer"
      className="sp-card d-flex flex-column text-decoration-none overflow-hidden h-100 pos-r"
    >
      <AspectRatioContainer aspectRatio={aspectRatio}>
        <img
          alt=""
          src={getStaticImageUrl(item.metadata(`image${suffix}`).value())}
          className="w-100 h-100 pos-a center-y"
          loading={position === 1 ? 'eager' : 'lazy'}
          decoding={position === 1 ? 'auto' : 'async'}
        />
      </AspectRatioContainer>

      {urlTargetNew && <AdLabel />}

      <div
        className={classnames('d-flex flex-column h-100', {
          'card-disclaimer px-md-0 py-md-0_5 px-lg-1 py-lg-1': isTextOverlay,
          'pt-0_5': !isTextOverlay,
        })}
      >
        {hasRowDisclaimer && (
          <div
            className={classnames('row-disclaimer mb-0_25', {
              'size-10': isTextOverlay,
              'disclaimer text-gray-darker': !isTextOverlay,
            })}
          >
            {item.metadata('disclaimer').value()}
          </div>
        )}
        <ContentFragment
          classes={classnames('verbiage mb-0_25', {
            'size-16': isTextOverlay,
            medium: !isTextOverlay,
          })}
        >
          {item.metadata('verbiage').value()}
        </ContentFragment>
        <div className={classnames('learn-more mt-auto small', { 'text-primary-darker': !isTextOverlay })}>
          {item.metadata('urlText').value('Learn more')}
          <StickyIcon
            className={classnames('size-10', {
              'icon-open_in_new': urlTargetNew,
              'icon-arrow-right3': !urlTargetNew,
            })}
          />
        </div>
      </div>
    </a>
  );
}
Card.propTypes = {
  item: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
  hasRowDisclaimer: PropTypes.bool,
  isTextOverlay: PropTypes.bool,
  position: PropTypes.number,
};

Card.defaultProps = {
  isMobile: false,
  hasRowDisclaimer: false,
  isTextOverlay: false,
  position: null,
};

export function HomeMarqueeUI({ content, className, isMobile, isTextOverlay }) {
  const cards = content.children();
  const hasRowDisclaimer = new Array(Math.ceil(cards.length / COL_COUNT))
    .fill()
    .map(
      (value, index) =>
        !!(
          (cards[index * 2] && cards[index * 2].metadata('disclaimer').value()) ||
          (cards[index * COL_COUNT + 1] && cards[index * COL_COUNT + 1].metadata('disclaimer').value())
        )
    );
  const col = 12 / COL_COUNT;

  return (
    <Fragment>
      {cards.length ? (
        <Row className={classnames('home-marquee', className)} data-tracking-parent="edm-entry-home-page-hero">
          {cards.map((item, index) => (
            <Col xs={col} className="pb-1" key={item.id}>
              <Card
                item={item}
                isMobile={isMobile}
                isTextOverlay={isTextOverlay}
                hasRowDisclaimer={hasRowDisclaimer[Math.floor(index / COL_COUNT)]}
                position={index + 1}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="home-marquee empty-marquee" aria-hidden data-tracking-parent="edm-entry-home-page-hero" />
      )}
      <div>
        {times(HERO_HPM_ADS, i => (
          <AdUnit key={i} {...HERO_HPM_ADS_PROPS[isMobile]} position={`${i + 1}`} renderWhenViewable={false} oneByOne />
        ))}
      </div>
    </Fragment>
  );
}

HomeMarqueeUI.propTypes = {
  content: CmsEntities.Content,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  isTextOverlay: PropTypes.bool,
};

HomeMarqueeUI.defaultProps = {
  content: DEFAULT_CONTENT,
  className: null,
  isMobile: false,
  isTextOverlay: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

const stateToPropsConfig = {
  content: bindToPath('homeHeroContent', HomeModulesModel, transform),
};

function MinHeightContainer({ useMinHeight, rows, children }) {
  if (useMinHeight) {
    return <MinHeightWrapper componentType={`HOME_MARQUEE_${rows}`}>{children}</MinHeightWrapper>;
  }
  return children;
}

function HomeMarqueeWrapper({ useMinHeight, ...restProps }) {
  const rows = Math.ceil(restProps.content && restProps.content.children().length / 2);

  return (
    <MinHeightContainer useMinHeight={useMinHeight} rows={rows}>
      <HomeMarqueeUI {...restProps} />
    </MinHeightContainer>
  );
}

export const HomeMarquee = connect(mapStateToProps)(connectToModel(HomeMarqueeWrapper, stateToPropsConfig));
HomeMarquee.displayName = 'HomeMarquee';
